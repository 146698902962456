import { create } from "zustand";
import { persist, devtools } from "zustand/middleware"; // devtools allows Zustand to interface with the Redux DevTools extension.

const useStore = create(
  devtools(
    persist(
      (set) => ({
        eventid: "",
        reservationphone: "",
        hostphonenumber: "",
        hostAccountNumber: "",
        hostBankName: "",
        user: null,
        isLoggedIn: false,
        guestname: "",
        isSignInOpen: false,

        setEventid: (id) => set({ eventid: id }),
        setReservationPhone: (phone) => set({ reservationphone: phone }),
        setHostPhoneNumber: (phone) => set({ hostphonenumber: phone }),
        setGuestName: (name) => set({ guestname: name }),
        setHostAccountNumber: (accountnumber) =>
          set({ hostAccountNumber: accountnumber }),
        setHostBankName: (accountname) => set({ hostBankName: accountname }),

        login: (userData) => set({ user: userData, isLoggedIn: true }),
        logout: () =>
          set({
            eventid: "",
            reservationphone: "",
            hostphonenumber: "",
            user: null,
            guestname: "",
            isLoggedIn: false,
          }),
        openSignInModal: () => set({ isSignInOpen: true }),
        closeSignInModal: () => set({ isSignInOpen: false }),
      }),
      {
        name: "event-store", // unique name/Key in localStorage
        // partialize: (state) => ({ eventid: state.eventid, user: state.user }), //If you only need to persist certain parts of the state, use the partialize option in the persist middleware to specify which slices of the state should be saved.
      }
    ),
    {
      name: "EventStore", // Label in Redux DevTools
      enabled: process.env.NODE_ENV === "development", // Enable only in development
    }
  )
);

export default useStore;
