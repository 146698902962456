import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PhoneMockup from '../components/phone';
import DonationComponent from '../components/DonationComponent';
import UploadComponent from '../components/UploadComponent';
import ReservationBox from '../components/reservationbox';
import ReservationPass from '../components/reservationpass';
import EventDetails from '../components/eventdetails';
import {
 faImage, faVideo,
  faUpload, faIdCard, faBroadcastTower,
  faInfoCircle, faCalendarCheck,faMusic,faMoneyBillWave,
} from '@fortawesome/free-solid-svg-icons';
import GlassIconDiv from '../components/glass-icon';
import CounterComponent from '../components/countercomponent';
import TooltipButton from '../components/tooltipbutton';
import event from '../assets/event.jpg';
import { getEvent, getMediaByEvent } from '../services/api';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainNavigation from '../layout/clientnavmain';
import SignIn from '../components/signin';
import useStore from "../store/event-store";
// import FancyButton from '../components/fancybutton';

const GuestView = () => {
  // State for modal components
  const [isReservationBoxOpen, setIsReservationBoxOpen] = useState(false);
  const [isReservationPassOpen, setIsReservationPassOpen] = useState(false);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [isDonationBoxOpen, setIsDonationBoxOpen] = useState(false);
  const [isUploadBoxOpen, setIsUploadBoxOpen] = useState(false);
  const [coverImg, setCoverImg] = useState(event);
  const [eventDetails, setEventDetails] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    program: "",
  });

  const navigate = useNavigate();

  const { isSignInOpen, closeSignInModal } = useStore();

  // Event details for the EventDetails component
  const eventDetail = {
    eventname: eventDetails.eventname,
    theme: eventDetails.theme,
    color: eventDetails.color,
    location: { name: eventDetails.location}, 
    date:  new Date(eventDetails.date).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric"}),
    phoneNumber: eventDetails.contactperson,
    description: eventDetails.description,
  };

  // State and effect for changing background color every 3 minutes
  const [bgColor, setBgColor] = useState('bg-black');
  const colors = ['bg-black', 'bg-red-500', 'bg-green-500', 'bg-blue-500', 'bg-yellow-500', ' bg-custom-gradient'];

  const { eventId } = useParams(); // Use useParams to get eventId from the URL

  useEffect(() => {
    const interval = setInterval(() => {
      setBgColor(prevColor => {
        const currentIndex = colors.indexOf(prevColor);
        return colors[(currentIndex + 1) % colors.length];
      });
    }, 15000); // 15 sec in milliseconds

    return () => clearInterval(interval);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    const fetchCoverPic = async()=>{
      try {
        const response = await getMediaByEvent(eventId)
        const data = response.data;
        setCoverImg(data[0].url);
      } catch (error) {
        // toast.error("Error getting cover picture");
      }
    };
    const fetcheventData = async ()=>{
      try {
        const response = await getEvent(eventId)
        // console.log(response.data)
        setEventDetails(response.data);
      } catch (error) {
        console.log("errror",error);
      }
    }
    if(eventId){
      fetchCoverPic();
      fetcheventData()
    }
  },[])// eslint-disable-line react-hooks/exhaustive-deps

  // State for handling mobile navigation
  // const [isNavOpen, setIsNavOpen] = useState(false);

  return (
      <div className={`relative transition-colors duration-1000 ${bgColor}`}>
        {/* Modals */}
        {isDonationBoxOpen && <DonationComponent closeDonationBox={() => setIsDonationBoxOpen(false)} />}
        {isUploadBoxOpen && <UploadComponent closeUploadBox={() => setIsUploadBoxOpen(false)} eventid={eventId} />}
        {isReservationPassOpen && <ReservationPass formData={formData} closeModal={() => setIsReservationPassOpen(false)} eventid={eventId} />}
        {isEventDetailsOpen && <EventDetails {...eventDetail} closeModal={() => setIsEventDetailsOpen(false)} />}
        {isSignInOpen && (<SignIn eventid={eventId} closeModal={closeSignInModal}/>)}

        <div className="flex flex-col min-h-screen justify-center">
          {/* Navigation */}
          <MainNavigation />

          {/* Main Content */}
          <div className="flex flex-col lg:flex-row justify-around items-center text-white p-4 mt-16">
            {/* Counter and RSVP */}
            <div className="bg-white text-black rounded-lg p-6 max-w-xs w-full flex flex-col items-center mt-4 shadow-lg">
              <CounterComponent targetDate={eventDetails.date} />
              <TooltipButton onClick={() => setIsReservationBoxOpen(true)} btntext={"Accept Invitation"} btncolor="bg-custom-green" information={"make reservation"} />
                {/* <FancyButton onClick={() => setIsReservationBoxOpen(true)} label={"Accept Invitation"}/> */}
              {isReservationBoxOpen && (
                <ReservationBox
                  closeReservationBox={() => setIsReservationBoxOpen(false)}
                  setFormData={setFormData}
                  openReservationPass={() => setIsReservationPassOpen(true)}
                  eventid={eventId}
                />
              )}
            </div>

            {/* Phone Mockup and Grid Section */}
            <div className="flex flex-col lg:flex-row items-center p-4 space-y-4 lg:space-y-0 lg:space-x-4">
              <PhoneMockup eventid={eventId} />

              <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 w-full max-w-xs">
                <Link to={`/preeventpage/${eventId}`} className="flex flex-col items-center space-y-2">
                  <GlassIconDiv icon={faMusic} />
                  <span className="text-sm text-center">Pre-event pictures</span>
                </Link>
                <Link to={`/gallery/${eventId}`} className="flex flex-col items-center space-y-2">
                  <GlassIconDiv icon={faImage} />
                  <span className="text-sm text-center">Images of events</span>
                </Link>
                <Link to={`/videos/${eventId}`} className="flex flex-col items-center space-y-2">
                  <GlassIconDiv icon={faVideo} />
                  <span className="text-sm text-center">Videos of events</span>
                </Link>
                <div onClick={() => setIsDonationBoxOpen(true)} className="flex flex-col items-center space-y-2 cursor-pointer">
                  <GlassIconDiv icon={faMoneyBillWave} />
                  <span className="text-sm text-center">Donations for Event</span>
                </div>
                <div onClick={() => setIsUploadBoxOpen(true)} className="flex flex-col items-center space-y-2 cursor-pointer">
                  <GlassIconDiv icon={faUpload} />
                  <span className="text-sm text-center">Upload event files</span>
                </div>
                <div onClick={() => setIsReservationPassOpen(true)} className="flex flex-col items-center space-y-2 cursor-pointer">
                  <GlassIconDiv icon={faIdCard} />
                  <span className="text-sm text-center">Events QR-code</span>
                </div>
                <Link to={`/livestream/${eventId}`} className="flex flex-col items-center space-y-2">
                  <GlassIconDiv icon={faBroadcastTower} />
                  <span className="text-sm text-center">Livestream events</span>
                </Link>
                <div onClick={() => setIsEventDetailsOpen(true)} className="flex flex-col items-center space-y-2 cursor-pointer">
                  <GlassIconDiv icon={faInfoCircle} />
                  <span className="text-sm text-center">Additional event information</span>
                </div>
                <div onClick={() => setIsReservationBoxOpen(true)} className="flex flex-col items-center space-y-2 cursor-pointer">
                  <GlassIconDiv icon={faCalendarCheck} />
                  <span className="text-sm text-center">Make reservation</span>
                </div>
              </div>

              {/* Information Section with Image */}
              <div className="flex flex-col items-center text-center max-w-lg mt-3 lg:mt-0">
                <div className="w-full max-w-md cursor-pointer" onClick={() => navigate(`/preeventpage/${eventId}`)}>
                  <img 
                    src={coverImg} 
                    alt="Event Information"
                    className="w-full h-auto max-h-[400px] rounded-lg object-cover"
                  />
                </div>
                <h1 className="text-4xl mt-4 mb-3">For More information About The Event</h1>
                <p className="text-lg mb-5">Information such as location, dress code?</p>
                <TooltipButton btntext={"More on event"} btncolor="bg-custom-green" information={"more information"} onClick={() => setIsEventDetailsOpen(true)} />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
  );
};

export default GuestView;