import React, { useState } from "react";
import { getUserByPhone, SignInApi } from "../services/api";
import useStore from "../store/event-store";
import { toast } from "react-toastify";

const SignIn = ({ eventid, closeModal }) => {
  const { setReservationPhone, setGuestName } = useStore();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      
      const response = await SignInApi(formData, eventid);
      
      if (response.data && response.data.response) {
        const res = await getUserByPhone(response.data.reservationPhone);
        
        const { name } = res.data;
        setGuestName(name);
        setReservationPhone(formData.phone);

        toast.success("Signed in successfully!");
        setTimeout(() => closeModal(), 500); // Close after success
      }
    } catch (error) {
      setLoading(false);

      if (error.response?.data?.error === "User not found") {
        toast.error("User not found. Please accept the invitation to sign in.");
      } else {
        toast.error("An error occurred while signing in.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-1/3 lg:w-1/4">
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-700 transition"
          >
            Close
          </button>
        </div>
        <h2 className="text-2xl font-semibold text-center mb-4">RSVP Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-gray-700 font-medium">
              Phone Number
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 p-2 border rounded w-full focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Enter your phone number"
              required
            />
          </div>
          <button
            type="submit"
            className={`px-4 py-2 w-full rounded text-white transition ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600 hover:bg-green-700"
            }`}
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign In"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;